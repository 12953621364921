// Node Modules
import css from "styled-jsx/css";

// Theme
import theme from "@/theme";

export const getFooterStyles = () => {
  return css.resolve`
    footer {
      align-items: center;
      border-top: 1px solid ${theme.brandColors.primaryGray};
      color: ${theme.brandColors.white};
      display: flex;
      font-family: Arial;
      font-size: 3.4rem;            
      height: 7.1%;
      justify-content: start;
      width: 100%;
    }

    div.call-button-container {
      background-color: ${theme.brandColors.white};
      height: 98%;
      width: 49%;
    }

    .tooltip-children-container {
      height: 100%;
      width: 100%;
    }

    
  `;
};
