// Node Modules
import css from "styled-jsx/css";

// Theme
import theme from "@/theme";

export const getSwipeButtonStyles = (btnHeight: number, disabled: boolean) => {
  const circleHeight = btnHeight * 0.8;
  const btnPrimaryColor = disabled
    ? theme.brandColors.fiftyGray
    : theme.brandColors.primaryGray;

  return css.resolve`
    .ReactSwipeButton {
      float: left;
      height: ${btnHeight}px;
      position: relative;
      width: 100%;
    }
    .ReactSwipeButton,
    .ReactSwipeButton * {
      -khtml-user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;   
      -moz-user-select: none;      
      -ms-user-select: none;
      user-select: none;  
    }
    .rsbContainer{
      background: ${btnPrimaryColor};
      border-radius: 50px;
      float: left;
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
      z-index:0;
    }
    .rsbContainerUnlocked{
      cursor: default;
      transition: 0.5s;
      width: 100%!important;
    }
    .rsbContainerUnlocked .rsbcSliderArrow {
      display: none;
    }
    .rsbContainerUnlocked .rsbcSlider{
      cursor: default;
      left: 100%!important;
      pointer-events: none;
    }
    .rsbContainerUnlocked .rsbcSliderCircle {
      display: none;
    }
    .rsbcText {
      color: ${theme.fontColors.white};
      float: left;
      font-family: ${theme.fontFamilies.arial};
      font-size: 3rem;
      font-weight: 600;
      height: ${btnHeight}px;
      left:0;
      letter-spacing: 2px;
      line-height: ${btnHeight}px;
      padding-left: ${btnHeight * 0.7}px;
      position: absolute; 
      right:0;
      text-align: center;
      text-transform: uppercase;
      top:0;
    }
    .rsbcSlider {
      align-content: center;
      background: ${btnPrimaryColor};
      border-radius: ${btnHeight / 2}px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      float: left;
      height: ${btnHeight}px;
      left: ${btnHeight * 0.9}px;
      margin-left:-100%;
      position: absolute;
      top: 0px;
      width: 100%;
      z-index: 100;
    }
    .rsbcSlider-border{
      height: ${circleHeight}px;
      width: 100%;
    }
    .rsbcSliderCircle {
      align-content: center;
      background: ${theme.brandColors.white};
      border-radius: 100%;
      color: ${btnPrimaryColor};
      display: flex;
      flex-wrap: wrap;
      height: ${circleHeight}px;
      justify-content: center;
      position: absolute;
      right: 0px;
      text-align: center;
      width: ${circleHeight}px;
    }
    .rsbcSliderText{
      color: ${theme.fontColors.white};
      font-family: ${theme.fontFamilies.arial};
      font-size: 3rem;
      font-weight: 600;
      left:0;
      letter-spacing: 2px;
      line-height: ${btnHeight}px;
      position: absolute;
      right:0;
      text-align: center;
      text-transform: uppercase;
      top:0;
    }
  `;
};
