// Enums
import HttpStatusCode from "@/enums/HttpStatusCode";

// Node Modules
import jwt_decode, { JwtPayload } from "jwt-decode";

// Scripts
import { getApiRoutesUrl } from "@/scripts/kioskUtil";

// Services
import API from "@/functions/axiosExternalInstance";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import ICreateVideoTaskPayload from "@/interfaces/twilio/task-router/ICreateVideoTaskPayload";
import IDeleteVideoTaskPayload from "@/interfaces/twilio/task-router/IDeleteVideoTaskPayload";

/**
 * Creates a video task in Twilio's task router, which in flex acts as an incoming video call.
 * @param identity is the identity attribute that will be included in the task.
 * @param kioskId indicates the kiosk/identifier to which the agent has to join in flex.
 * @param name indicates the name of the room to which the agent has to join in flex.
 * @param storeNumber indicates the store number to flex.
 * @returns the taskSid, which is the task identifier.
 */
const createVideoTask = async (name: string, identity: string, kioskId: string, ozStoreId: string, storeNumber: string, twilioVideoSessionId: string, program?:string, timeout?:number): Promise<string> => {
  const payload: ICreateVideoTaskPayload = {
    callOrigin: "kiosk",
    identity,
    kioskId,
    ozStoreId,
    name,
    storeNumber,
    twilioVideoSessionId,
    program,
    timeout,
  };

  const url = `${getApiRoutesUrl()}twilio/task-router/createVideoTask/`;
  const response = await API.post(url, payload);

  if (response.status != HttpStatusCode.OK) {
    throw Error(`CREATE_VIDEO_TASK_ERROR: An error occurred trying to create a task in Twilio for Room: [${name}], TWILIO_HTTP_STATUS_CODE: [${response.status}] TWILIO_ERROR_MSG: [${response.data}]`);
  }

  const taskSid: string = response.data as string;
  return taskSid;
};

/**
 * Function that allows to delete a Video Task from Twilio's task Router.
 * @param taskSid is the Task Identifier, to delete the task from Twilio's cloud.
 * @throws an error if the task deletion is not successful.
 */
const deleteVideoTask = async (taskSid: string): Promise<void> => {
  const payload: IDeleteVideoTaskPayload = {
    taskSid,
  };

  const url = `${getApiRoutesUrl()}twilio/task-router/deleteVideoTask/`;
  const response = await API.post(url, payload);

  if (response.status != HttpStatusCode.OK) {
    throw Error(`DELETE_VIDEO_TASK_ERROR: An error occurred trying to delete the task with id: [${taskSid}] in Twilio, TWILIO_HTTP_STATUS_CODE: [${response.status}] TWILIO_ERROR_MSG: [${response.data}]`);
  }
};

const getAudioToken = async (identity: string, timeToLiveInSeconds?: number): Promise<string> => {
  let token: string = undefined;

  try {
    let numRetries = 0;
    let authSuccess = false;
    while (numRetries < 3 && !authSuccess) {
      token = await getTokenFromApi(identity, timeToLiveInSeconds?.toString());
      numRetries++;
      authSuccess = true;
    }
  } catch (error) {
    logOnDataDog(error, StatusType.error);
  }

  return token;
};

const getTokenFromApi = async (identity: string, timeToLiveInSeconds?: string): Promise<string> => {
  const queryParameters = new URLSearchParams({
    identity,
    timeToLiveInSeconds,
  }).toString();

  const url = `${getApiRoutesUrl()}voice/audioToken/?${queryParameters}`;
  const response = await API.get(url);

  const {
    token,
  } = response.data;

  return token;
};

const getVideoToken = async (roomName: string): Promise<string> => {
  try {
    const queryParameters = new URLSearchParams({
      roomName,
    }).toString();

    const url = `${getApiRoutesUrl()}twilio/video/get/token/?${queryParameters}`;
    const response = await API.get(url);

    const {
      token,
    } = response.data;

    return token;
  } catch (error) {
    logOnDataDog(error, StatusType.error);
    throw new Error("An error occurred trying to get the video token from Twilio");
  }
};

const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return true;
  }

  const decodedToken = jwt_decode<JwtPayload>(token);
  const {
    exp,
  } = decodedToken;

  const expiryDate = new Date(exp * 1000);
  const now = new Date();
  return now >= expiryDate;
}

export default {
  createVideoTask,
  deleteVideoTask,
  getAudioToken,
  getVideoToken,
  isTokenExpired,
};
