// Types
import {
  SizeOption,
  SizeOptions
} from "@/classes/scripts/SizeOptionsProps";

export const accessibilitySizeOptions: SizeOptions = {
  small: {
    size: "small",
    zoom: 1,
  },
  medium: {
    size: "medium",
    zoom: 1.1,
  },
  large: {
    size: "large",
    zoom: 1.2,
  },
};

export const getAccessibilitySize = (document: Document): SizeOption => {
  const childrenBodyElement = document.getElementById("children-body");
  const fontSize = childrenBodyElement

    // @ts-expect-error: zoom not included in CSSStyleDeclaration list
    ? window.getComputedStyle(childrenBodyElement)?.zoom
    : 1;

  switch (fontSize) {
    case accessibilitySizeOptions.large.zoom.toString():
      return accessibilitySizeOptions.large;
    case accessibilitySizeOptions.medium.zoom.toString():
      return accessibilitySizeOptions.medium;
    case accessibilitySizeOptions.small.zoom.toString():
      return accessibilitySizeOptions.small;
    default:
      return accessibilitySizeOptions.small;
  }
};

export const setZoom = (document: Document, fontSizeValue: number): void => {
  const childrenBodyElement = document.getElementById("children-body");

  // @ts-expect-error: zoom not included in CSSStyleDeclaration list
  childrenBodyElement.style.zoom = fontSizeValue;
};

export const resetZoom = (document: Document): void => {
  setZoom(document, accessibilitySizeOptions.small.zoom)
};
