// Classes
import {
  Default,
  Email,
  KeyboardLayout,
  Numeric,
  NumericOptions
} from "@/classes/keyboard/KeyboardLayout";

// Components
import SimpleKeyboard from "react-simple-keyboard";

// Context
import { useKeyboardContext } from "@/context/KeyboardContext";

// Enums
import { Key } from "@/enums/Key";
import { KeyboardLayoutName } from "@/gc/keyboard/enums/KeyboardLayoutName";
import { KeyboardType } from "@/enums/KeyboardType";

// Hooks
import useOnClickOutside from "@/hooks/useOnClickOutside";

// Node Modules
import "react-simple-keyboard/build/css/index.css";
import {
  FunctionComponent,
  useRef,
  useState
} from "react";

// Theme
import theme from "@/data/theme";

const KeyboardWrapper: FunctionComponent = () => {
  const [currentKeyboardLayout, setCurrentKeyboardLayout] = useState<string>(KeyboardLayoutName.default);

  const {
    Keyboard,
    setKeyboard,
  } = useKeyboardContext();

  const getInputValueWithoutLasCharacter = (): string => {
    const {
      inputValue,
    } = Keyboard;

    return inputValue?.length
      ? inputValue?.slice(0, -1)
      : "";
  };

  const onKeyPress = (button: string): void => {
    if (button === Key.shift) {
      setCurrentKeyboardLayout(currentKeyboardLayout === KeyboardLayoutName.default
        ? KeyboardLayoutName.shift
        : KeyboardLayoutName.default);
    }

    if (button === Key.backspace) {
      const inputValueWithoutLastString = getInputValueWithoutLasCharacter();

      Keyboard.onChange(inputValueWithoutLastString);
    }
  };

  const closeKeyboard = (): void => {
    setKeyboard({
      ...Keyboard,
      showKeyboard: false,
    });
  };

  const keyboardContainerRef = useRef();

  useOnClickOutside(keyboardContainerRef, () => closeKeyboard(), Keyboard.invokerRef);

  const getLayoutObjectsForKeyboard = (layoutName: KeyboardType): KeyboardLayout[] => {
    const layouts: KeyboardLayout[] = [];

    switch (layoutName) {
      case KeyboardType.email:
        layouts.push(new Email(), new Default());
        break;
      case KeyboardType.default:
        layouts.push(new Default());
        break;
      case KeyboardType.numeric:
        layouts.push(new Numeric(), new NumericOptions());
        break;
    }

    return layouts;
  };

  const getKeyboards = (): JSX.Element[] => {
    const keyboards: JSX.Element[] = [];
    const layouts = getLayoutObjectsForKeyboard(Keyboard.layoutName);

    layouts.forEach((layout, index) => {
      const layoutAsAnObject = layout.getLayoutAsAnObject();

      keyboards.push(
        <SimpleKeyboard
          display={{
            [Key.backspace]: "⌫",
            [Key.enter]: "Enter",
            [Key.shift]: "Shift ⇧",
            [Key.space]: " ",
          }}
          key={index}
          keyboardRef={ref => (Keyboard.keyboardRef.current = ref)}
          layout={layoutAsAnObject}
          layoutName={currentKeyboardLayout}
          theme={`hg-theme-default hg-layout-numeric light ${layout.theme}`}
          onChange={Keyboard.onChange}
          onKeyPress={onKeyPress}
        />
      );
    });

    return keyboards;
  };

  return (
    <>
      <div
        className="keyboard-container"
        data-testid="keyboard-container"
        ref={keyboardContainerRef}
      >
        {getKeyboards()}
      </div>
      <style
        global
        jsx
      >
        {`
          #children-body {
            height: 66.3%;
          }
          
          .default {
            width: 100%;
          }

          .email .hg-button{
            background-color: ${theme.surfaceColors.silver};
            border-radius: 3rem;
            margin: 1rem;
            width: 100%
          }

          .hg-button.hg-functionBtn.hg-button-shift {
            background-color: ${currentKeyboardLayout === "shift" && theme.surfaceColors.silver};
            width: 13%;
          }

          .hg-button.hg-functionBtn.hg-button-space {
            width: 79%;
          }

          .hg-button.hg-standardBtn[data-skbtn=".com"]{
            width: 8%;
          }

          .hg-button.hg-standardBtn[data-skbtn="@"]{
            max-width: 9.3rem;
          }
          
          .keyboard-container {
            bottom: 0;
            display: ${Keyboard.showKeyboard
          ? "flex"
          : "none"};
            flex-wrap: wrap;
            position: fixed;
            width: 100%;
            z-index: 9999;
          } 

          .light {
            background-color: ${theme.brandColors.backgroundGray};
            border-radius: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            font-size: 2.5rem;
          }
          
          .hg-button {
            align-items: center;
            background: ${theme.brandColors.white};
            color: ${theme.brandColors.black};
            display: flex;
            font-size: 3.5rem;
            height: 50px;
            justify-content: center;
          }
          
          .hg-button-backspace {
            color: ${theme.fontColors.primaryGreen}
          }
          
          .light .hg-button:active {
            background-color: ${theme.surfaceColors.silver};
          }

          .numeric {
            width: 80%;
          }

          .numeric-options {
            width: 20%;
          }

          .numeric-options [data-skbtn="{backspace}"]{
            height: 6rem;
          }

          .numeric-options [data-skbtn="{enter}"]{
            height: 19rem !important;
          }
          
        `}
      </style>
    </>
  );
};

export default KeyboardWrapper;
