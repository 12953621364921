// Node Modules
import css from "styled-jsx/css"

export const StylesDiagnosticsPasswordModal = () => {
  return css.resolve`
    .disclaimer {
      font-size: 2.5rem;
    }

    .disclaimer-container {
      margin-top: 10rem;
    }

    .password-input-field-container {
      margin-top: 6rem;
    }

    .continue-button-container {
      margin-top: 10rem;
    }
    `
}

