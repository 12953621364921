// Helpers
import { formatRemoteProgram } from "@/pages/api/helpers/stringHelper";

// Node Modules
import { StopwatchResult } from "react-timer-hook";
import {
  Dispatch,
  SetStateAction
} from "react";
import Video, {
  LocalTrack,
  LocalTrackPublication,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  TwilioError,
} from "twilio-video";

// Scripts
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Services
import dataLayerService from "@/components/services/dataLayerService";
import twilioService from "@/components/services/twilioService";
import {
  StatusType,
  logOnDataDog
} from "@/components/services/dataDogLoggingService";

// Types
import { CallError } from "@/enums/CallError";
import { ICallErrorModalModified } from "@/src/models/contentful/ICallErrorModalModified";
import ICallErrorModalProps from "@/interfaces/global-components/video/sub-components/ICallErrorModalProps";
import { ITwilioVideoProviderProps } from "@/classes/video-call/ITwilioVideoProviderProps";
import { VideoProvider } from "@/classes/video-call/VideoProvider";

export class TwilioVideoProvider extends VideoProvider {
  #room: Room | null = null;
  #localTracks: LocalTrack[] = [];
  #setCallErrorModalProps: Dispatch<SetStateAction<ICallErrorModalProps | undefined>>;
  #setIsRoomReady: Dispatch<SetStateAction<boolean>>;
  #setShowAutoCallModal: React.Dispatch<React.SetStateAction<boolean>>;
  #stopWatch: StopwatchResult;
  #taskSid?: string | null = null;
  #videoTaskTimeout: number | undefined;
  #hasAgentEnteredTheRoom: boolean = false;

  constructor(props: ITwilioVideoProviderProps) {
    super(props);
    this.#setCallErrorModalProps = props.setCallErrorModalProps;
    this.#setIsRoomReady = props.setIsRoomReady;
    this.#setShowAutoCallModal = props.setShowAutoCallModal;
    this.#stopWatch = props.stopWatch;
    this.#videoTaskTimeout = props.videoTaskTimeout;
  }

  public startOutboundCall = async (): Promise<void> => await this.#startCall(false);
  public answerInboundCall = async (): Promise<void> => await this.#startCall(true);

  public endCall = async (): Promise<void> => {
    try {
      this.resetCallState();
      this.#setShowAutoCallModal(false);
      await this.#deleteVideoTaskFromTwilio();
      if (this.#room) {
        this.#room.disconnect();
        this.#room = null;
        this.isCallActive = false;
        this.#hasAgentEnteredTheRoom = false;
        this.#taskSid = null;
        this.#stopWatch.reset(undefined, false);
      }
      this.#localTracks.forEach(track => track.kind !== "data" && track.stop());
      this.#localTracks = [];

      dataLayerService.pushEvent({
        event: dataLayerTypes.events.kioskVideoCallEnded,
      });
    } catch (error: any) {
      this.#handleErrorLogging(error);
    }
  }
 
  public reconnectToRoomAudio = (): void => {
    this.#subscribeTracksOfParticipantsThatWereInTheRoomAlready();
  };

  // ################# Private Methods #################

  #startCall = async (isInboundCall: boolean = false): Promise<void> => {
    this.isCallActive = true;
    
    try {
      const {
        kioskId,
        ozStoreId,
        program,
        storeNumber,
      } = this.deviceInformation;

      if (!kioskId || !ozStoreId || !storeNumber) {
        throw new Error("KIOSK_TWILIO_FLEX_ERROR::DEVICE_INFORMATION_ERROR");
      }

      if (!isInboundCall) {
        this.setPlayCallChime(true);
        this.#setShowAutoCallModal(true);
      }

      const kioskRoomName: string = `Site ${storeNumber}${formatRemoteProgram(program)}`;
      this.setDialingCallState(isInboundCall);

      this.#room = await this.#joinRoom(kioskRoomName);

      if (!isInboundCall) {
        const twilioVideoSessionId = this.#room.sid;
        const taskSid: string = await twilioService.createVideoTask(
          kioskRoomName,
          kioskRoomName,
          kioskId,
          ozStoreId,
          storeNumber,
          twilioVideoSessionId,
          undefined,
          this.#videoTaskTimeout
        );

        this.#taskSid = taskSid;
      } else {
        if (this.#room.participants.size > 0) {
          this.#handleOnRemoteParticipantConnectedEvent();
        }
      }
      
      this.#subscribeTracksOfParticipantsThatWereInTheRoomAlready();
      this.#room.on("participantConnected", this.#onRemoteParticipantConnectedEventHandler);
      this.#room.on("participantDisconnected", this.#onRemoteParticipantDisconnectedEventHandler);
      this.#room.on("disconnected", this.#onDisconnectedEventHandler);
      this.#room.on("reconnecting", this.#onReconnectingEventHandler);
      this.#room.on("reconnected", this.#onReconnectedEventHandler);
      this.#room.on("trackSubscriptionFailed", this.#onTrackSubscriptionFailedEventHandler);
      
      this.#setIsRoomReady(true);
      if (this.#taskSid || isInboundCall) {
        this.setIsUserAllowedToEndCallAlready(true);
      }
    } catch (error: any) {
      this.#setCallErrorModalContent(CallError.OUTGOING_FAILED);
      this.#handleErrorLogging(error);
      this.isCallActive = false;
    }
  }

  #joinRoom = async (kioskRoomName: string): Promise<Room> => {
    this.#localTracks = await Video.createLocalTracks({
      audio: true,
    });

    const connectOptions: Video.ConnectOptions = {
      tracks: this.#localTracks,
      automaticSubscription: true,
      name: kioskRoomName,
    };

    const videoToken: string = await twilioService.getVideoToken(kioskRoomName);
    const room = await Video.connect(videoToken, connectOptions);

    return room;
  };

  #deleteVideoTaskFromTwilio = async (): Promise<void> => {
    try {
      const agentHasNotJoinedYet: boolean = !this.#hasAgentEnteredTheRoom;
      const shouldDeleteVideoTask: boolean = !!this.#taskSid && agentHasNotJoinedYet;

      if (shouldDeleteVideoTask) {
        await twilioService.deleteVideoTask(this.#taskSid!);
        this.#taskSid = null;
      }
    } catch (error: any) {
      this.#handleErrorLogging(error);
    }
  };

  #detachLocalParticipantTracks = (room: Room): void => {
    room.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
      if (publication.track && publication.track.kind !== "data") {
        const attachedElements: HTMLMediaElement[] = publication.track.detach();
        attachedElements.forEach((element: HTMLMediaElement) => element.remove());
      }
    });
  };

  #handleErrorLogging = (error: Error): void => {
    if (error) {
      logOnDataDog(error.message, StatusType.error, {
        error,
        room: this.#room,
      });
    }
  };

  #handleOnRemoteParticipantConnectedEvent = (): void => {
    this.setPlayCallChime(false);
    this.#stopWatch.start();
    this.setAnsweredCallState();
  };

  #noRemoteParticipantsAreConnected = (): boolean => this.#room?.participants?.size === 0;

  /**
   * Handles the event of the local user disconnecting from the room.
   * @param room Represents the room, that the user is disconnecting from.
   * @param error In case the disconnection is due to an error, this will contain the error information.
   */
  #onDisconnectedEventHandler = (room: Room, error: TwilioError): void => {
    this.#detachLocalParticipantTracks(room);

    if (error) {
      const storeNumber: string = room.name;
      const object = {
        ...error,
        storeNumber,
      };

      logOnDataDog("KIOSK_TWILIO_FLEX_ERROR::ROOM_DISCONNECTED_EVENT", StatusType.error, object);
    }
  };

  /**
   * Handles the event of reconnection to the room after trying the reconnection.
   */
  #onReconnectedEventHandler = (): void => {
    this.#setCallErrorModalContent(undefined);
  };
  
  /**
   * Handles the event of trying to reconnect to the room after losing connection.
   * @param error This will contain the error information.
   */
  #onReconnectingEventHandler = (error: TwilioError): void => {
    this.#setCallErrorModalContent(CallError.RECONNECTING);
    this.#handleErrorLogging(error);
  };
  
  /**
   * Handles the event of a failed subscription to the remote tracks.
   * @param error This will contain the error information.
   * @param _publication This will contain the remote tracks information.
   * @param _participant This represents the remote participant for which the tracks could not be subscribed.
   */
  #onTrackSubscriptionFailedEventHandler = (error: TwilioError, _publication: RemoteTrackPublication, _participant: RemoteParticipant) => {
    this.#handleErrorLogging(error);
  };

  /**
   * Handles the event of remote participants joining the room.
   * @param participant Represents a remote participant, person on the other side of the call.
   */
  #onRemoteParticipantConnectedEventHandler = (participant: RemoteParticipant): void => {
    this.#hasAgentEnteredTheRoom = true;
    this.#setShowAutoCallModal(false);
    this.#handleOnRemoteParticipantConnectedEvent();
    this.#subscribeToAlreadyPublishedRemoteParticipantTracks(participant);
    this.#subscribeToFutureRemoteParticipantTracks(participant);
  };

  /**
   * Handles the event of remote participants disconnecting from the room.
   * @param participant Represents a remote participant, person on the other side of the call.
   */
  #onRemoteParticipantDisconnectedEventHandler = (participant: RemoteParticipant): void => {
    this.#unSubscribeFromRemoteParticipantTracks(participant);

    if (this.#noRemoteParticipantsAreConnected()) {
      this.endCall();
    }
  };

  #setCallErrorModalContent = (errorCode?: string): void => {
    if (!errorCode) {
      this.#setCallErrorModalProps(undefined);
      return;
    }

    const callErrorModalContent: ICallErrorModalModified | undefined =
      this.globalLayoutFields?.callErrorModals?.find((callErrorModal: ICallErrorModalModified) => callErrorModal.fields?.errorCode === errorCode);

    if (!callErrorModalContent?.fields?.countDownSeconds) {
      this.#setCallErrorModalProps(undefined);
      return;
    }

    const callErrorModalProps: ICallErrorModalProps = {
      initialCountDown: callErrorModalContent.fields.countDownSeconds,
      modalBodyText: callErrorModalContent?.fields.modalBodyText!,
      modalHeader: callErrorModalContent?.fields.modalHeader!,
      modalOnClose: this.endCall,
    }

    this.#setCallErrorModalProps(callErrorModalProps);
  };

  #subscribeToAlreadyPublishedRemoteParticipantTracks = (participant: RemoteParticipant): void => {
    participant.tracks.forEach((publication: RemoteTrackPublication) => {
      if (publication.isSubscribed) {
        const {
          track,
        } = publication;

        if (track && track.kind !== "data") {
          this.getRemoteMediaDiv().appendChild(track.attach());
        }
      }
    });
  };

  #subscribeToFutureRemoteParticipantTracks = (participant: RemoteParticipant): void => {
    participant.on("trackSubscribed", (track: RemoteTrack) => {
      if (track && track.kind !== "data") {
        this.getRemoteMediaDiv().appendChild(track.attach());
      }
    });
  };

  #subscribeTracksOfParticipantsThatWereInTheRoomAlready = (): void => {
    this.#room?.participants.forEach((participant: RemoteParticipant) => {
      participant.tracks.forEach((publication: RemoteTrackPublication) => {
        if (publication.track && publication.track.kind !== "data") {
          this.getRemoteMediaDiv().appendChild(publication.track.attach());
        }
      });

      participant.on("trackSubscribed", (track: RemoteTrack) => {
        if (track && track.kind !== "data") {
          this.getRemoteMediaDiv().appendChild(track.attach());
        }
      });
    });
  };

  #unSubscribeFromRemoteParticipantTracks = (participant: RemoteParticipant): void => {
    participant.tracks.forEach((publication: RemoteTrackPublication) => {
      if (publication.track && publication.track.kind !== "data") {
        publication.track
          .detach()
          .forEach((element: HTMLMediaElement) => element.remove());
      }
    });
  };
}
