// Types
import { KeyboardLayoutObject } from "react-simple-keyboard";

const mostCommonEmailDomainsList = "@gmail.com @yahoo.com";

export class KeyboardLayout {
  default: string[];
  shift?: string[];
  theme: string;

  constructor() {
    this.default;
    this.shift;
    this.theme;
  }

  getLayoutAsAnObject(): KeyboardLayoutObject {
    return {
      default: this.default,
      shift: this.shift,
    };
  }
}

export class Default extends KeyboardLayout {
  default = [
    "1 2 3 4 5 6 7 8 9 0 {backspace}",
    "q w e r t y u i o p -",
    "a s d f g h j k l : '",
    "@ z x c v b n m . ,",
    "{shift} {space} .com",
  ];

  shift = [
    "! ` # $ % ^ & * ( ) {backspace}",
    "Q W E R T Y U I O P _",
    "A S D F G H J K L ; \"",
    "@ Z X C V B N M < >",
    "{shift} {space} .com",
  ];

  theme = "default";
}

export class Email extends KeyboardLayout {
  default = [mostCommonEmailDomainsList];

  shift = [mostCommonEmailDomainsList];

  theme = "email";
}

export class Numeric extends KeyboardLayout {
  default = [
    "7 8 9",
    "4 5 6",
    "1 2 3",
    "0",
  ];

  theme = "numeric-theme numeric";
}

export class NumericOptions extends KeyboardLayout {
  default = ["{backspace}", "{enter}"];

  theme = "numeric-options";
}
