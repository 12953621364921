// Components
import TopBanner from "@/gc/banner/TopBanner";

// Context
import { useSystemOutageContext } from "@/context/SystemOutageContext";

// Node Modules
import {
  useEffect,
  useRef,
  useState
} from "react";

// Scripts
import { convertSecondsToMinutesFormat } from "@/scripts/validator";
import { getDate } from "@/scripts/dateHelper";

// Types
import { ISystemOutageBannerProps } from "@/interfaces/global-components/banner/ISystemOutageBannerProps";

const SystemOutageTopBanner = ({
  testId,
}: ISystemOutageBannerProps) => {
  const {
    attractionLoopVideoInformation,
    setShowSystemOutageBanner,
  } = useSystemOutageContext();

  const [secondsRemainingUntilOutage, setSecondsRemainingUntilOutage] = useState<number>();
  const [labelText, setLabelText] = useState<string>();

  const {
    bannerText,
  } = attractionLoopVideoInformation.bannerInfo.fields

  const getLabelText = (numberOfSeconds: number) =>
    `${bannerText} ${convertSecondsToMinutesFormat(numberOfSeconds)}`;

  const timerIntervalRef = useRef(null);
  const hideBanner = (): void => {
    setShowSystemOutageBanner(false);
    clearInterval(timerIntervalRef.current);
  };

  const getDateFromString = (date: string): Date | undefined => (date
    ? new Date(date)
    : undefined);

  const startCountDown = (): void => {
    const startDateTime = getDateFromString(attractionLoopVideoInformation?.startDateTime);

    if (!startDateTime) {
      return;
    }

    const now = getDate();
    const currentTimeInSeconds = (now.getHours()) * 60 * 60 + (now.getMinutes()) * 60 + (now.getSeconds());
    const startDateTimeInSeconds = (startDateTime.getHours()) * 60 * 60 + (startDateTime.getMinutes()) * 60 + (startDateTime.getSeconds());
    const numberOfSecondsRemainingUntilOutage: number = startDateTimeInSeconds - currentTimeInSeconds;

    setSecondsRemainingUntilOutage(numberOfSecondsRemainingUntilOutage);

    let activeCountDown = numberOfSecondsRemainingUntilOutage;

    timerIntervalRef.current = setInterval(() => {
      if (activeCountDown > 0) {
        setSecondsRemainingUntilOutage(--activeCountDown)
      } else {
        hideBanner();
      }
    }, parseInt(process.env.SYSTEM_OUTAGES_BANNER_SET_INTERVAL_MS));
  };

  useEffect(() => {
    startCountDown();
  }, []);

  useEffect(() => {
    setLabelText(getLabelText(secondsRemainingUntilOutage))
  }, [secondsRemainingUntilOutage, attractionLoopVideoInformation?.startDateTime])

  return (
    <TopBanner
      className="system-outage banner"
      label={labelText}
      testId={testId}
    />
  )
};

export default SystemOutageTopBanner;
