// Components
import Button from "@/components/global-components/button/Button";
import CenteredModal from "@/gc/modals/CenteredModal";
import { HFInputField } from "@/gc/form-components/hook-form";
import KeyboardWrapper from "@/gc/keyboard/KeyboardWrapper";

// Contexts
import { useKeyboardContext } from "@/context/KeyboardContext";

// Enums
import { KeyboardType } from "@/enums/KeyboardType";

// Material UI
import {
  FilledInput,
  FormControl,
  InputLabel,
} from "@mui/material";

// Node Modules
import SimpleKeyboard from "react-simple-keyboard";
import { useRouter } from "next/router";
import {
  useEffect,
  useRef,
  useState
} from "react";

// Styles
import { StylesDiagnosticsPasswordModal } from "./StylesDiagnosticsPasswordModal";

// Themes
import theme from "@/theme";

const DiagnosticsPasswordModal = ({
  setShowModal,
}) => {
  const {
    className,
    styles,
  } = StylesDiagnosticsPasswordModal();

  const {
    Keyboard,
    setKeyboard,
  } = useKeyboardContext();

  const [inputFieldValue, setInputFieldValue] = useState<string>();

  const keyboardRef = useRef<typeof SimpleKeyboard>();
  const invokerRef = useRef<typeof HFInputField>();

  const handleOnFocus = (inputValue: string): void => {
    setKeyboard({
      ...Keyboard,
      inputValue,
      invokerRef,
      keyboardRef,
      layoutName: KeyboardType.default,
      showKeyboard: true,
      onChange: setInputFieldValue,
    });
  };

  useEffect(() => {
    setKeyboard({
      ...Keyboard,
      inputValue: inputFieldValue,
    });
  }, [inputFieldValue]);

  const router = useRouter();
  const onSubmit = async () => {
    if (inputFieldValue === process.env.KIOSK_DIAGNOSTICS_PASSWORD) {
      router.push("/diagnostics");
    } else {
      // handle incorrect password
    }
  };

  const formControlStyle = {
    height: "12rem",
    marginTop: "3rem",
  };

  const inputLabelStyle = {
    color: theme.fontColors.primaryGray,
    fontSize: "2.8rem",
    paddingLeft: "2rem",
  };

  const filledInputStyle = {
    fontSize: "3.8rem",
    height: "14rem",
    paddingLeft: "2rem",
    paddingBottom: "2rem",
  };

  return (
    <>
      <CenteredModal
        testId="diagnostics-modal"
        title="Diagnostics Page Login"
        onClose={() => setShowModal(false)}
      >
        <FormControl
          style={formControlStyle}
          variant="filled"
        >
          <InputLabel
            htmlFor="password-input-field"
            style={inputLabelStyle}
          >
            Password
          </InputLabel>
          <FilledInput
            disableUnderline
            autoComplete="off"
            inputProps={{
              "data-testid": "password-input-field",
            }}
            placeholder="Password"
            ref={invokerRef}
            style={filledInputStyle}
            type="password"
            value={inputFieldValue}
            onChange={(event) => setInputFieldValue(event.target.value)}
            onFocus={(event) => handleOnFocus(event.target.value)}
          />
          <div className={`${className} continue-button-container`}>
            <Button
              backgroundColor={theme.brandColors.primaryGreen}
              className={`${className} continue-btn small`}
              testId="complete-step"
              title="CONTINUE"
              type="submit"
              width="100%"
              onClick={() => onSubmit()}
            />
          </div>
          <div className={`${className} disclaimer-container`}>
            <p className={`${className} disclaimer`}>This section is intended for authorized personnel only. If you have accessed this page by mistake, please close this window immediately.</p>
            <p className={`${className} disclaimer`}>If you have any questions, please contact support.</p>
          </div>
        </FormControl>
      </CenteredModal>
      {styles}
      {Keyboard.showKeyboard && (
        <KeyboardWrapper />
      )}
    </>

  );
};

export default DiagnosticsPasswordModal;
