// Components
import CenteredModal from "@/gc/modals/CenteredModal";

// Context
import { useKioskUserSessionContext } from "@/context/KioskUserSessionContext";

// Node Modules
import { useRouter } from "next/router";
import {
  useEffect,
  useRef,
  useState
} from "react";

// Scripts
import { convertSecondsToMinutesFormat } from "@/scripts/validator";
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Services
import dataLayerService from "@/services/dataLayerService";

// Types
import ITimerModalProps from "@/interfaces/global-components/modals/ITimerModalProps";

const TimerModal = ({
  content,
  passiveTimerFunctionality,
}: ITimerModalProps) => {
  const {
    activeTimerSeconds = 60,
    passiveTimerSeconds = 150,
    timerModalTitle,
    timerModalMessage,
    timerModalButtonText,
    
  } = content.globalLayout?.fields?.timer?.fields || {};

  const activeTimerSecsRef = useRef(activeTimerSeconds);
  const passiveTimerSecsRef = useRef(passiveTimerSeconds);

  const router = useRouter();
  const [showActiveTimerModal, setShowActiveTimerModal] = useState(false);
  const [activeTimerRemainingSeconds, setActiveTimerRemainingSeconds] = useState(activeTimerSeconds);

  const {
    setShowAttractionLoop,
  } = useKioskUserSessionContext();

  const passiveIntervalRef = useRef(null);
  const activeIntervalRef = useRef(null);

  const clearTimers = () => {
    clearInterval(passiveIntervalRef.current);
    clearInterval(activeIntervalRef.current);
  };

  const pushTimerExpiredEvent = () => {
    dataLayerService.pushEvent({
      event: dataLayerTypes.events.timerExpired,
      pageType: router.pathname,
    });
  };

  const resetTimer = () => {
    passiveTimerSecsRef.current = passiveTimerSeconds;
  };

  const startActiveTimer = () => {
    activeIntervalRef.current = setInterval(() => {
      if (activeTimerSecsRef.current > 0) {
        setActiveTimerRemainingSeconds(--activeTimerSecsRef.current);
      } else {
        clearInterval(activeIntervalRef.current);
        pushTimerExpiredEvent();
        if (router.pathname === "/") {
          setShowAttractionLoop(true);
        } else {
          router.push({
            pathname: "/",
            query: {
              displayAttractionLoop: true,
            },
          });
        }
      }
    }, 1000);
  };

  resetTimer();

  const startTimer = () => {
    passiveIntervalRef.current = setInterval(() => {
      if (--passiveTimerSecsRef.current < 0) {
        if (passiveTimerFunctionality !== undefined) {
          clearInterval(passiveIntervalRef.current);
          passiveTimerFunctionality();
        } else {
          setShowActiveTimerModal(true);
          clearInterval(passiveIntervalRef.current);
          startActiveTimer();
        }
      }
    }, 1000);
  };

  const configureTimer = () => {
    startTimer();

    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
      "touchmove",
      "touchcancel",
      "touchend",
    ];

    events.forEach(name => {
      document.addEventListener(name, resetTimer, true);
    });
  };

  const closePassiveTimerModal = () => {
    setShowActiveTimerModal(false);
    configureTimer();

    if (activeIntervalRef.current) {
      clearInterval(activeIntervalRef.current);
      setActiveTimerRemainingSeconds(activeTimerSeconds);
    }
  };

  useEffect(() => {
    if (process.env.KIOSK_TIMER_ENABLED === "true") {
      configureTimer();
    }

    return () => clearTimers();
  }, [passiveTimerFunctionality]);

  return (
    <div
      data-testid="timer-modal"
    >
      {showActiveTimerModal && (
        <CenteredModal
          buttonOnClick={closePassiveTimerModal}
          buttonText={timerModalButtonText}
          id="timer-continue-btn"
          isModalClosable={false}
          message={timerModalMessage}
          title={timerModalTitle}
        >
          <h1
            className="remaining-time"
            data-testid="remaining-time"
          >
            {`${convertSecondsToMinutesFormat(activeTimerRemainingSeconds)}`}
          </h1>
        </CenteredModal>
      )}
      <style>
        {`
          .remaining-time { 
            font-weight: bold;
            height: 2.5em;
            margin: auto;
            text-align: center;
            width: 6em;
          }
        `}
      </style>
    </div>
  );
};

export default TimerModal;
