// Themes
import theme from "@/theme";

// Types
import IStopwatchProps from "@/interfaces/global-components/voice/sub-components/IStopwatchProps";

const Stopwatch = ({
  minutes,
  seconds,
}: IStopwatchProps) => {
  const timeFragmentToLocale = (timeFragment: number) => timeFragment?.toLocaleString("en-US",
    {
      minimumIntegerDigits: 2,
    });

  const separator: string = (minutes && seconds) || (minutes >= 0 && seconds >= 0)
    ? ":"
    : "";

  return (
    <>
      <span
        className="stopwatch"
        data-testid="stopwatch"
      >
        {timeFragmentToLocale(minutes)}{separator}{timeFragmentToLocale(seconds)}
      </span>

      <style jsx>
        {`         
          span.stopwatch {
            color: ${theme.textColors.black};
            margin: 0% 4%;
            font-weight: bold;
          }
        `}
      </style>
    </>
  )
}

export default Stopwatch
