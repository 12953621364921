// Components
import DiagnosticsPasswordModal from "@/components/diagnostics/modal/DiagnosticsPasswordModal";

// Node Modules
import Image from "next/legacy/image";
import {
  useCallback,
  useEffect,
  useState
} from "react";

// Styles
import { StylesHeader } from "./StylesHeader";

// Types
import IHeaderProps from "@/interfaces/global-components/header/IHeaderProps";

const Header = ({
  content,
  shouldShowDiagnosticsModal,
}: IHeaderProps) => {
  const essLogo = content?.globalLayout?.fields?.essLogo;
  const isLogoUrlDefined: boolean = !!essLogo?.fields?.file?.url;
  const [tapCount, setTapCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleLogoClick = useCallback(() => {
    if (shouldShowDiagnosticsModal) {
      if (tapCount === 0) {
        const id = setTimeout(() => {
          setTapCount(0);
        }, 5000);

        setTimeoutId(id);
      }

      setTapCount(previousCount => previousCount + 1);
    }
  }, [tapCount, shouldShowDiagnosticsModal]);

  useEffect(() => {
    if (tapCount >= 7 && shouldShowDiagnosticsModal) {
      setShowModal(true);
      setTapCount(0);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [
    tapCount,
    timeoutId,
    shouldShowDiagnosticsModal,
  ]);

  const {
    className,
    styles,
  } = StylesHeader();

  return (
    <div className={`header ${className}`}>
      <div className={`logo-container ${className}`}>
        {isLogoUrlDefined && (
          <Image
            priority
            alt={essLogo.fields.title}
            data-testid="logo"
            height={104}
            src={`https:${essLogo.fields.file.url}`}
            width={232}
            onClick={handleLogoClick}
          />
        )}
      </div>
      {showModal && (
        <DiagnosticsPasswordModal setShowModal={setShowModal} />
      )}

      {styles}
    </div>
  );
};

export default Header;
