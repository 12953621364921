// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import { SwipeButton } from "@/components/global-components/swipe-button/SwipeButton";

// FontAwesome Icons
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

// Themes
import theme from "@/theme";

// Types
import IStartCallButtonProps from "@/interfaces/global-components/video/sub-components/IStartCallButtonProps";

const StartCallButton = ({
  content,
  disabled = false,
  startCall,
}: IStartCallButtonProps) => {
  const globalLayoutFields = content?.globalLayout?.fields;

  return (
    <>
      (
      <SwipeButton
        disabledProp={disabled}
        successText={globalLayoutFields.footerDialingButtonText}
        swipeIcon={(
          <FontAwesomeIcon
            icon={faPhoneAlt}
          />
        )}
        unlockText={globalLayoutFields.footerCallSwipeButton}
        onSuccess={startCall}
      />
      )
      <style jsx>
        {`

          button {
            background-color: ${theme.brandColors.primaryGray};
            border: none;
            border-radius: 10rem;
            color: ${theme.fontColors.white};
            font-size: 3.4rem;
            font-weight: bold;
            height: 80%;
            text-align: center;
            text-transform: uppercase;
          }

          button.start-call {
            width: 100%;
          }

          button.end-call {
            background-color: ${theme.surfaceColors.black};
            width: 55%;
          }

         .btn-label {
            margin-left: 4.5%;
            text-transform: uppercase;
            font-weight: bold;
          }

        `}
      </style>
    </>
  )
}

export default StartCallButton;
