export const emailRegex = RegExp(
  /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/
);

// This checks that email has '@' and '.xx' at least. Does not check if email is vaild

export const isValidEmail = email => {
  let isValidEmail = false;
  isValidEmail = email && emailRegex.test(email.trim());
  return isValidEmail;
};

export const sanitizedPhoneNumber = sanitize => {
  const sanitizedNumber = sanitize.replace(/-|\s/g, "");
  return sanitizedNumber;
};

// Phone number standard (xxx)-XXX-XXXX

export const formatPhoneNumber = phoneNumber => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1]
      ? "+1 "
      : "";

    return [

      intlCode,

      "(",

      match[2],

      ")-",

      match[3],

      "-",

      match[4],

    ].join("");
  }

  return null;
};

// Returns number and converts into minutes and seconds

export const convertSecondsToMinutesFormat = seconds => {
  try {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  } catch {
    return "00:00";
  }
};
