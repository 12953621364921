// Material UI
import Popper from "@mui/material/Popper";

// Node Modules
import styled from "@emotion/styled";

interface IToolTipStyles{
  blocktab: boolean;
  defaulticonsize: string;
  fontsize: string;
  maxwidth: string;
}

export const TooltipStyles = styled("div") <IToolTipStyles>`
  display: inline-block;
  line-height: 0px;
  ${props => (props.blocktab
    ? "height:100%; width: 100%;"
    : "")}

  & .default-children{
    display: inline-block;
    font-size: ${props => props.defaulticonsize};
    padding: 0px 5px;
  }

  & .MuiTooltip-popper{
    background: #FFFFFF;
    border: 1px solid #CECECE;
    box-shadow: 0px 2px 12px rgba(87, 102, 117, 0.5);
    z-index: 4;

    & div.MuiTooltip-tooltip.MuiTooltip-touch{
      font-weight: 400;
      font-size: ${props => props.fontsize};
      line-height: 31px;
      color: #54565B;
      background: #FFFFFF;
      margin: 0,
      padding: 1.6rem 3.2rem;
      max-width: ${props => props.maxwidth}
    }
  } 
`;

interface IStyledPopper{
  fontSize: string;
  maxwidth: string;
}

export const StylesPopper = styled(Popper) <IStyledPopper>`
  color: red;
  background: #FFFFFF;
  border: 1px solid #CECECE;
  box-shadow: 0px 2px 12px rgba(87, 102, 117, 0.5);
  z-index: 4;

  & div.MuiTooltip-tooltip {
    font-weight: 400;
    font-size: ${(props) => props.fontSize} ;
    line-height: 35px;
    color: #54565B;
    background: #FFFFFF;
    margin: 0;
    padding: 1.6rem 3.2rem;
    max-width: ${(props) => props.maxwidth} ;
  }

  & div.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementLeft, 
    div.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight, 
    div.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop, 
    div.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom{
    margin: 0px;
  }
`;

