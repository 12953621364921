// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// FontAwesome Icons
import { faArrowAltRight } from "@fortawesome/pro-solid-svg-icons";
import theme from "@/data/theme";

// Node Modules
import { getSwipeButtonStyles } from "./StylesSwipeButton";
import React, {
  useEffect,
  useRef,
  useState
} from "react"

// Types
import { ISwipeButtonProps } from "@/interfaces/global-components/swipe-button/ISwipeButtonProps";

const defaultIcon = <FontAwesomeIcon icon={faArrowAltRight} />;

const getClientX = (e: any) => {
  let x = 0
  if (e.touches && e.touches[0]) {
    x = e.touches[0].clientX;
  } else {
    x = e.clientX ?? 0;
  }

  return x
}

export const SwipeButton = ({
  disabledProp = false,
  disabledText,
  forceReset = false,
  height = 100,
  id,
  onFailure,
  onSuccess,
  successText = "UNLOCKED",
  swipeIcon = defaultIcon,
  unlockText = "SLIDE TO UNLOCK",
}: ISwipeButtonProps) => {
  const isDragging = useRef(false);
  const disabled = useRef(disabledProp);
  const sliderLeft = useRef(0);
  const containerWidth = useRef(0);
  const sliderStartXAxis = useRef(0);
  const [unlocked, setUnlocked] = useState(false);
  const container = useRef(null);
  const slider = useRef(null);
  const [forceRenderValue, setForceRenderValue] = useState(true);
  const forceRender = () => {
    setForceRenderValue(!forceRenderValue);
  }

  const {
    className,
    styles,
  } = getSwipeButtonStyles(height, disabled.current);

  const startDrag = (e: any) => {
    if (unlocked || disabled.current) {
      return;
    }

    isDragging.current = true;

    slider.current.style.backgroundColor = theme.brandColors.moderateGreen;

    sliderStartXAxis.current = getClientX(e);
  }

  const onDrag = (e: any) => {
    if (unlocked || disabled.current) {
      return;
    }

    if (isDragging.current) {
      const sliderLeftCalculation = Math.min(Math.max(0, getClientX(e) - sliderStartXAxis.current), containerWidth.current);
      sliderLeft.current = sliderLeftCalculation;
      updateSliderStyle();
    }
  }

  const stopDrag = () => {
    if (unlocked || disabled.current) {
      return;
    }

    if (isDragging.current) {
      isDragging.current = false;

      if (sliderLeft.current > containerWidth.current * 0.9) {
        sliderLeft.current = containerWidth.current;
        updateSliderStyle();
        handleOnSuccess();
      } else {
        resetSwipeBtn()

        if (onFailure) {
          onFailure();
        }
        updateSliderStyle();
      }
    }
  }

  const updateSliderStyle = () => {
    if (unlocked || disabled.current) {
      return;
    }

    const leftPx = (sliderLeft.current + (height * 0.9)) + "px";
    slider.current.style.left = leftPx;
    forceRender();
  }

  const handleOnSuccess = () => {
    container.current.style.width = `${container.current.clientWidth}px`;
    setUnlocked(true);

    if (onSuccess) {
      onSuccess();
    }
  }

  const getText = () => {
    if (unlocked) {
      return successText;
    }

    if (disabled.current) {
      const disableTextExists = disabledText
        ? true
        : false;

      return disableTextExists
        ? disabledText
        : unlockText;
    }

    return unlockText;
  }

  const resetSwipeBtn = () => {
    setUnlocked(false);
    sliderLeft.current = 0;
    slider.current.style.backgroundColor = theme.brandColors.primaryGray;
    forceRender();
  }

  const addEventListeners = () => {
    document.addEventListener("touchmove", onDrag);
    document.addEventListener("touchend", stopDrag);
    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", stopDrag);
  }

  const removeEventListeners = () => {
    document.removeEventListener("touchmove", onDrag);
    document.removeEventListener("touchend", stopDrag);
    document.removeEventListener("mousemove", onDrag);
    document.removeEventListener("mouseup", stopDrag);
  }

  useEffect(() => {
    addEventListeners();
    containerWidth.current = container.current.clientWidth - height;

    return () => {
      removeEventListeners();
    }
  }, []);

  useEffect(() => {
    if (forceReset) {
      resetSwipeBtn();
    }
  }, [forceReset]);

  useEffect(() => {
    disabled.current = disabledProp;
    forceRender();
  }, [disabledProp]);

  return (
    <>
      <div
        className={`${className} ReactSwipeButton`}
        id={id}
        role="swipe-btn-container"
      >
        <div
          className={`${className} rsbContainer ${unlocked
            ? "rsbContainerUnlocked"
            : ""}`}
          ref={container}
        >
          <div
            className={`${className} rsbcSlider`}
            ref={slider}
            role="slider-btn"
            onMouseDown={startDrag}
            onTouchStart={startDrag}

          >
            <div className={`${className} rsbcSlider-border`}>
              <span className={`${className} rsbcSliderText`}>{(isDragging.current || unlocked) && getText()}</span>
              <span
                className={`${className} rsbcSliderCircle`}
              >
                {swipeIcon}
              </span>
            </div>

          </div>
          <div className={`${className} rsbcText`}>{getText()}</div>
        </div>
      </div>
      {styles}
    </>
  )
}
