// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// FontAwesome Icons
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

// Themes
import theme from "@/theme";

// Types
import IBackButtonProps from "@/interfaces/global-components/footer/sub-components/IBackButtonProps";

const BackButton = ({
  content,
  disableBackButton,
  handleBack,
}: IBackButtonProps) => {
  const globalLayoutFields = content?.globalLayout?.fields;

  return (
    <>
      {
        disableBackButton || !handleBack
          ? (
            <div
              className="back-btn"
              data-testid="back-btn"
            />
            )
          : (
            <div
              className="back-btn"
              data-testid="back-btn"
              onClick={() => handleBack()}
            >
              <FontAwesomeIcon
                color={theme.textColors.black}
                icon={faChevronLeft}
                size="lg"
              />
              <span className="btn-label">{globalLayoutFields?.footerBackButtonText}</span>
            </div>
            )
      }

      <style jsx>
        {`
          .btn-label {
            margin-left: 4.5%;
            text-transform: uppercase;
            font-weight: bold;
          } 

          div.back-btn{
            background-color: ${theme.brandColors.white};
            color: ${theme.textColors.black};
            padding-left: 4%;
            width: 34%;
          }
        `}
      </style>
    </>
  )
}

export default BackButton
