// Theme
import theme from "@/theme";

const Loader = () => {
  return (
    <>
      <div className="loading-overlay">
        <div
          className="loading-icon"
          data-testid="loading-icon"
        >
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle
              className="path"
              cx="25"
              cy="25"
              fill="none"
              r="20"
              strokeWidth="7"
            />
          </svg>
        </div>
      </div>
      <style jsx>
        {`
          .loading-overlay {
            align-items: center;
            display: flex;
            font-size: 5rem;
            height: 83.3%;
            justify-content: center;
            width: 100%;
            z-index: 6;        
            background-color: rgba(255, 255, 255, 0.251);        
            top: 9.6%;
            position: absolute;
          }

          .loading-overlay .spinner {
            position: fixed;
            top: 50%;
            left: 50%;
            width: 3em;
            height: 3em;
            margin: -1.5em 0 0 -1.5em;
            z-index: 7;
            animation: rotate 2s linear infinite;
          }

          .loading-overlay .spinner .path {
            stroke: ${theme.brandColors.primaryGreen};
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
          }

          @keyframes rotate {
            100% { transform: rotate(360deg); }
          }

          @keyframes dash {
            0% {
              stroke-dasharray: 1, 150;
              stroke-dashoffset: 0;
            }
            50% {
              stroke-dasharray: 90, 150;
              stroke-dashoffset: -35;
            }
            100% {
              stroke-dasharray: 90, 150;
              stroke-dashoffset: -124;
            }
          }
        `}
      </style>
    </>
  );
};

export default Loader;
