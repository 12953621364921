/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
// Components
import ErrorBody from "@/components/error/ErrorBody";
import React from "react";

// Services
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Node Modules
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.props.setHasError(true);

    this.setState((prevState) => ({
      ...prevState,
      hasError: true,
    }));

    logOnDataDog(error, StatusType.error);

    console.error({
      error,
      errorInfo,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError !== this.props.hasError) {
      this.setState({
        ...prevState,
        hasError: this.props.hasError,
      })
    }
  }

  render() {
    const {
      errorTitle,
      errorBodyText,
      errorImage,
      errorImageHeight,
      errorImageWidth,
    } = this.props?.content.globalLayout.fields.errorFields.fields;

    if (this.state.hasError) {
      return (
        <ErrorBody
          data-testid="errorBody"
          errorBodyText={errorBodyText}
          errorImage={errorImage}
          errorImageHeight={errorImageHeight}
          errorImageWidth={errorImageWidth}
          errorTitle={errorTitle}
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  errorTitle: PropTypes.string,
  errorBodyText: PropTypes.any,
  errorImage: PropTypes.any,
  errorImageHeight: PropTypes.any,
  errorImageWidth: PropTypes.any,
}

export default ErrorBoundary;
