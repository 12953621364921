// Node modules
import {
  useEffect,
  useRef,
  useState
} from "react";

// Themes
import theme from "@/theme";

// Types
import IIncomingCallButtonProps from "@/interfaces/global-components/video/sub-components/IIncomingCallButtonProps";

const IncomingCallButton = ({
  chimeOnEnd,
  globalLayout,
  playChime,
}: IIncomingCallButtonProps): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [readyToPlay, setReadyToPlay] = useState<boolean>(false);
  const buttonLabel = globalLayout.fields.footerIncomingCallText;
  const audioSrcUrl = globalLayout.fields.chime?.fields.file.url;

  const incomingCallButtonProps = {
    className: "start-call",
    id: "start-call-btn",
    "data-testid": "start-call-btn",
    style: {
      display: playChime
        ? "inline-block"
        : "none",
    },
  };

  const startChime = (): void => {
    if (readyToPlay && audioRef.current) {
      audioRef.current.play()
        .catch(() => {
          chimeOnEnd();
        });
    } else {
      chimeOnEnd();
    }
  }

  useEffect(() => {
    if (playChime) {
      startChime();
    }
  }, [playChime]);

  return (
    <>
      <audio
        className="audio-element"
        data-testid="chime"
        ref={audioRef}
        onCanPlay={() => setReadyToPlay(true)}
        onEnded={chimeOnEnd}
        onError={() => setReadyToPlay(false)}
      >
        <source src={audioSrcUrl} />
      </audio>
      <button {...incomingCallButtonProps}>
        <span className="btn-label">
          {buttonLabel}
        </span>
      </button>

      <style jsx>
        {`

          button {
            background-color: ${theme.brandColors.primaryGray};
            border: none;
            border-radius: 10rem;
            color: ${theme.fontColors.white};
            font-size: 3.4rem;
            font-weight: bold;
            height: 80%;
            text-align: center;
            text-transform: uppercase;
          }

          button.start-call {
            width: 100%;
          }

         .btn-label {
            margin-left: 4.5%;
            text-transform: uppercase;
            font-weight: bold;
          }

        `}
      </style>
    </>
  )
}

export default IncomingCallButton;
