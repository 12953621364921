// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// Themes
import theme from "@/theme";

// Types
import IConnectingCallButtonProps from "@/interfaces/global-components/video/sub-components/IConnectingCallButtonProps";

const ConnectingCallButton = ({
  callState,
  iconDefinition,
  globalLayout,
  isIncomingCall,
  isRoomReady,
  onClick,
}: IConnectingCallButtonProps) => {
  const roomReadyClassName = isRoomReady
    ? "room-ready"
    : "";

  const connectingButtonProps = {
    className: `start-call ${roomReadyClassName}`,
    id: "start-call-btn",
    onClick: !isIncomingCall
      ? onClick
      : undefined,
    "data-testid": "connecting-call-btn",
  };

  const incomingCallLabel = globalLayout.fields.footerIncomingCallText;

  const buttonLabel = isIncomingCall
    ? incomingCallLabel
    : callState.buttonLabel;

  return (
    <>
      <button {...connectingButtonProps}>
        <FontAwesomeIcon {...iconDefinition} />
        <span className="btn-label">
          {buttonLabel}
        </span>
      </button>

      <style jsx>
        {`

          button {
            background-color: ${theme.brandColors.primaryGreen};
            border: none;
            border-radius: 10rem;
            color: ${theme.fontColors.black};
            font-size: 3.4rem;
            font-weight: bold;
            height: 80%;
            text-align: center;
            text-transform: uppercase;
          }

          button.start-call {
            width: 100%;
          }

         .btn-label {
            margin-left: 4.5%;
            text-transform: uppercase;
            font-weight: bold;
          }

        `}
      </style>
    </>
  )
}

export default ConnectingCallButton;
