// Themes
import theme from "@/theme";

// Types
import IBannerProps from "@/interfaces/global-components/banner/IBannerProps";

const TopBanner = ({
  backGroundColor,
  className,
  fontColor,
  label,
  testId,
}: IBannerProps) => {
  return (
    <>
      <div
        className={className
          ? `${className} banner`
          : "banner"}
        data-testid={testId}
      >
        <p>{label}</p>
      </div>
      <style jsx>
        {`
          p {
            font-size: 2.8rem;
          }
          
          .banner {
            background-color: ${backGroundColor ?? theme.banners.accentGreen};
            align-items: center;
            color: ${fontColor ?? theme.fontColors.primaryGray};
            display: flex;
            font-weight: bold;
            height: 10rem;
            justify-content: center;
            width: 100%;
          }
          .system-outage{
            position:sticky;
            top:0;
          }
          .system-outage > p{
            font-size:3.2rem;
          }
        `}
      </style>
    </>
  );
};

export default TopBanner;
