import Script from "next/script";

const OptimizelyScriptComponent = () => (
  <Script
    async
    crossOrigin="anonymous"
    data-testid="optimizely-script"
    src="https://cdn.optimizely.com/js/20907712829.js"
    strategy="afterInteractive"
  />
);

export default OptimizelyScriptComponent;
