// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// FontAwesome Icons
import { faHome } from "@fortawesome/pro-solid-svg-icons";

// Node Modules
import Link from "next/link";

// Themes
import theme from "@/theme";

// Types
import IHomeButtonProps from "@/interfaces/global-components/footer/sub-components/IHomeButtonProps";

const HomeButton = ({
  homeButtonOnClick,
  isHomeButtonEnabled,
}: IHomeButtonProps) => {
  const homeStyle = (!isHomeButtonEnabled)
    ? "home-btn disabled"
    : "home-btn";

  const homeIcon = (
    <FontAwesomeIcon
      color="white"
      icon={faHome}
      size="6x"
    />
  );

  return (
    <>
      <div
        className={homeStyle}
        data-testid="home-btn"
      >
        {
          homeButtonOnClick
            ? (
              <a
                data-testid="home-btn-link"
                onClick={homeButtonOnClick}
              >
                {homeIcon}
              </a>
              )
            : (
              <Link
                legacyBehavior
                href="/"
              >
                <a
                  data-testid="home-btn-link"
                >
                  {homeIcon}
                </a>
              </Link>
              )
        }
      </div>

      <style jsx>
        {`
          div.home-btn {
            background-color: ${theme.brandColors.primaryGray};
            height: 100%;
            font-size: 0.9rem;
            width: 12.6%;
            display: flex;
            align-items: center;
            justify-content: center;    
          }

          div.home-btn.disabled {
            background-color:${theme.brandColors.fiftyGray};
            pointer-events: none;
          }
        `}
      </style>
    </>
  )
}

export default HomeButton;
