// Node Modules
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// Types
import IRichTextProps from "@/interfaces/global-components/rich-text/IRichTextProps";

const RichText = ({
  document,
  options,
}: IRichTextProps) => {
  return (
    <>
      {documentToReactComponents(document, options)}
    </>
  );
};

export default RichText;
