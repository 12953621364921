// Font Awesome
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MUTooltip from "@mui/material/Tooltip";

// Node Modules
import {
  useEffect,
  useRef,
  useState
} from "react";

// Styles
import {
  StylesPopper,
  TooltipStyles
} from "./StylesToolTip";

// Types
import { IToolTipProps } from "@/interfaces/global-components/tooltip/ITooltipProps";

const defaultChildren = (
  <span
    className="default-children"
  >
    <FontAwesomeIcon
      icon={faQuestionCircle}
    />
  </span>
);

const Tooltip = ({
  children = defaultChildren,
  childrenClassName = "",
  closingTime = 6000,
  disable = false,
  isChildrenBlockTag,
  defaultIconSize = "2.2rem",
  maxWidth = "35rem",
  onOpenAction,
  placement = "bottom",
  spaceBetweenChildren = 20,
  toolTipContent,
  toolTipClassName = "",
  tooltipTextFontSize = "3.5rem",
}: IToolTipProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const tooltipTimeOut = useRef<NodeJS.Timeout>(null);

  const closeTooltip = (): void => {
    setOpen(false);
    clearTimeout(tooltipTimeOut.current);
  };

  useEffect(() => {
    if (open) {
      startTimerForAutomaticClose();
    }
  }, [open]);

  const startTimerForAutomaticClose = (): void => {
    tooltipTimeOut.current = setTimeout(() => {
      closeTooltip();
    }, closingTime);
  };

  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>): void => {
    if (!disable) {
      event.stopPropagation();
      setOpen(true);

      if (onOpenAction) {
        onOpenAction();
      }
    }
  };

  return (
    <ClickAwayListener
      onClickAway={closeTooltip}
    >
      <TooltipStyles
        blocktab={isChildrenBlockTag}
        className={`root ${childrenClassName}`}
        defaulticonsize={defaultIconSize}
        fontsize={tooltipTextFontSize}
        maxwidth={maxWidth}
        role="tooltip"
        onClick={handleTooltipOpen}
        onTouchStart={handleTooltipOpen}
      >
        <MUTooltip
          disableFocusListener
          PopperComponent={(props: any) => (
            <StylesPopper
              {...props}
              fontSize={tooltipTextFontSize}
              maxwidth={maxWidth}
            />
          )}
          PopperProps={{
            disablePortal: false,
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, spaceBetweenChildren],
                },
              },
            ],
          }}
          classes={{
            popper: `popper ${toolTipClassName}`,
          }}
          open={open}
          placement={placement}
          title={toolTipContent}
        >
          {children}
        </MUTooltip>
      </TooltipStyles>
    </ClickAwayListener>
  );
};

export default Tooltip;
