// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import { SwipeButton } from "@/components/global-components/swipe-button/SwipeButton";

// FontAwesome Icons
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Themes
import theme from "@/theme";

// Types
import IButtonProps from "@/interfaces/global-components/voice/sub-components/IButtonProps";

const Button = ({
  content,
  callState,
  endCall,
  iconDefinition,
  startCall,
  disabled,
}: IButtonProps) => {
  const globalLayoutFields = content?.globalLayout?.fields;

  const connectingButtonProps = {
    className: "connecting-call",
    id: "connecting-call-btn",
    onClick: () => endCall(),
  };

  const defaultButtonProps = {
    id: "start-call-btn",
    onSuccess: () => startCall(),
  };

  const endCallButtonProps = {
    className: "end-call",
    id: "end-call-btn",
    onClick: () => endCall(),
    "data-testid": "end-call-btn",
  };

  const [startCallButtonProps, setStartCallButtonProps] = useState(defaultButtonProps);

  useEffect(() => {
    handleCallStatusChange();
  }, [callState, disabled])

  const handleCallStatusChange = () => {
    if (callState.callStatus === callStatuses.connecting) {
      setStartCallButtonProps(props => ({
        ...props,
        onSuccess: () => endCall(),
      }));
    } else {
      setStartCallButtonProps(defaultButtonProps);
    }
    if (disabled) {
      setStartCallButtonProps(disabledButtonProps => ({
        ...disabledButtonProps,
        onSuccess: () => endCall(),
      }));
    }
  };
  return (
    <>
      {callState.callStatus === callStatuses.inactive && (
        <SwipeButton
          disabledProp={disabled}
          successText={globalLayoutFields.footerDialingButtonText}
          swipeIcon={(
            <FontAwesomeIcon
              icon={faPhoneAlt}
            />
          )}
          unlockText={globalLayoutFields.footerCallSwipeButton}
          {...startCallButtonProps}
        />
      )}

      {callState.callStatus === callStatuses.active && (
        <button {...endCallButtonProps}>
          <FontAwesomeIcon {...iconDefinition} />
          <span className="btn-label">
            {callState.buttonLabel}
          </span>
        </button>
      )}

      {callState.callStatus === callStatuses.connecting && (
        <button {...connectingButtonProps}>
          <FontAwesomeIcon {...iconDefinition} />
          <span className="btn-label">
            {callState.buttonLabel}
          </span>
        </button>
      )}

      <style jsx>
        {`

          button {
            background-color: ${theme.brandColors.primaryGray};
            border: none;
            border-radius: 10rem;
            color: ${theme.fontColors.white};
            font-size: 3.4rem;
            font-weight: bold;
            height: 80%;
            text-align: center;
            text-transform: uppercase;
          }
          button.disabled{
            width:85%;
            background-color: ${theme.brandColors.disabledGrey};
          }

          button.start-call {
            width: 100%;
          }
          
          button.end-call {
            background-color: ${theme.brandColors.red};
            width: 60%;
          }

          button.connecting-call {
            background-color: ${theme.brandColors.primaryGreen};
            color: ${theme.fontColors.black};
            width: 100%;
          }

         .btn-label {
            margin-left: 4.5%;
            text-transform: uppercase;
            font-weight: bold;
          }

        `}
      </style>
      <style>
        {`
          button.end-call svg path {
            transform: rotate(135deg);
            transform-origin: 40% 50%;
          }
        `}
      </style>
    </>
  )
}

export default Button
