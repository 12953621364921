// Components
import CallErrorModal from "@/components/global-components/video/sub-components/CallErrorModal";
import ConnectingCallButton from "@/components/global-components/video/sub-components/ConnectingCallButton";
import EndCallButton from "@/gc/video/sub-components/EndCallButton";
import IncomingCallButton from "@/gc/video/sub-components/IncomingCallButton";
import StartCallButton from "@/gc/video/sub-components/StartCallButton";

// Context
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useTimerContext } from "@/context/TimerContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// FontAwesome Icons
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

// Node Modules
import {
  useCallback,
  useEffect,
  useState
} from "react";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Themes
import theme from "@/theme";

// Types
import ICallErrorModalProps from "@/interfaces/global-components/video/sub-components/ICallErrorModalProps";
import ICustomIconDefinitionProps from "@/interfaces/global-components/icons/ICustomIconDefinitionProps";
import { IGlobalLayoutFieldsModified } from "@/interfaces/layouts/IGlobalLayoutFieldsModified";
import { ITwilioVideoProviderProps } from "@/classes/video-call/ITwilioVideoProviderProps";
import IVideoHandlerProps from "@/interfaces/global-components/video/IVideoHandlerProps";
import { TwilioVideoProvider } from "@/classes/video-call/TwilioVideoProvider";
import { VideoProvider } from "@/classes/video-call/VideoProvider";

const VideoHandler = ({
  content,
  disabled,
}: IVideoHandlerProps): JSX.Element => {
  const globalLayoutFields: IGlobalLayoutFieldsModified = content.globalLayout.fields;
  const videoTaskTimeout: number | undefined = content.globalLayout.fields.twilioTaskTimeout;
  const [callErrorModalProps, setCallErrorModalProps] = useState<ICallErrorModalProps | undefined>(undefined);
  const [isRoomReady, setIsRoomReady] = useState<boolean>(false);
  const [iconDefinition, setIconDefinition] = useState<ICustomIconDefinitionProps>({
    icon: faPhoneAlt,
    size: "lg",
  });

  const {
    callState,
    isIncomingCall,
    isUserAllowedToEndCallAlready,
    setCallState,
    setIsIncomingCall,
    setIsUserAllowedToEndCallAlready,
    setPlayCallChime,
    setShowAutoCallModal,
    setVideoProvider,
    setWasCallDisconnectedByNscAgent,
    stopWatch,
    videoProvider,
    wasCallDisconnectedByNscAgent,
  } = useVideoCallContext();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    startCallFunctionRef,
  } = useCustomerSupportContext();

  const {
    disableTimer,
    enableTimer,
  } = useTimerContext();

  const getVideoProviderInstance = useCallback((): VideoProvider => {
    if (videoProvider) {
      return videoProvider;
    }

    const props: ITwilioVideoProviderProps = {
      deviceInformation,
      disableTimer,
      enableTimer,
      globalLayoutFields,
      setCallErrorModalProps,
      setCallState,
      setIconDefinition,
      setIsInboundCall: setIsIncomingCall,
      setIsRoomReady,
      setIsUserAllowedToEndCallAlready,
      setPlayCallChime,
      setShowAutoCallModal,
      stopWatch,
      videoTaskTimeout,
    }

    const newVideoProvider = new TwilioVideoProvider(props);
    setVideoProvider(newVideoProvider);

    return newVideoProvider;
  }, [
    deviceInformation,
    disableTimer,
    enableTimer,
    globalLayoutFields,
    setCallErrorModalProps,
  ]);

  const startOutboundCall = useCallback(async (): Promise<void> => {
    const videoProvider = getVideoProviderInstance();
    if (!videoProvider) {
      console.error("Video provider is not initialized yet.");
      return;
    }

    await videoProvider.startOutboundCall();
  }, [getVideoProviderInstance]);

  const answerInboundCall = useCallback(async (): Promise<void> => {
    const videoProvider = getVideoProviderInstance();
    if (!videoProvider) {
      console.error("Video provider is not initialized yet.");
      return;
    }

    await videoProvider.answerInboundCall();
  }, [getVideoProviderInstance]);

  const endCall = useCallback(async (): Promise<void> => {
    const videoProvider = getVideoProviderInstance();
    if (!videoProvider) {
      console.error("Video provider is not initialized yet.");
      return;
    }

    await videoProvider.endCall();
  }, [getVideoProviderInstance]);

  useEffect(() => {
    // This will run when the user navigates
    // between pages while connected to a call.
    if (videoProvider?.isCallActive) {
      videoProvider.reconnectToRoomAudio();
    }
  }, []);

  useEffect(() => {
    // This will run if the NSC agent makes the call and disconnects it before the kiosk can answer it.
    // E.g They made a call to a Kiosk by mistake and they want to cancel it vert quickly.
    if (wasCallDisconnectedByNscAgent && !videoProvider?.isCallActive) {
      setTimeout(() => {
        endCall();
        setWasCallDisconnectedByNscAgent(false);
      }, 1000);
    }
  }, [wasCallDisconnectedByNscAgent]);

  useEffect(() => {
    startCallFunctionRef.current = startOutboundCall;
  }, [startOutboundCall]);

  return (
    <>
      {callErrorModalProps && (
        <CallErrorModal
          {...callErrorModalProps}
        />
      )}
      <div
        className="videocall-handler-container"
        role="video-call-handler-container"
      >
        <div
          className="preview"
          id="remote-media"
          style={{
            display: "inline-block",
            alignItems: "center",
          }}
        />
        {!isIncomingCall && (callState.callStatus === callStatuses.inactive) && (
          <StartCallButton
            content={content}
            disabled={disabled}
            startCall={startOutboundCall}
          />
        )}

        <IncomingCallButton
          chimeOnEnd={answerInboundCall}
          globalLayout={content.globalLayout}
          playChime={isIncomingCall && (callState.callStatus === callStatuses.inactive)}
        />

        {callState.callStatus === callStatuses.connecting && (
          <ConnectingCallButton
            callState={callState}
            globalLayout={content.globalLayout}
            iconDefinition={iconDefinition}
            isIncomingCall={isIncomingCall}
            isRoomReady={isRoomReady}
            onClick={isUserAllowedToEndCallAlready
              ? endCall
              : undefined}
          />
        )}

        {callState.callStatus === callStatuses.active && (
          <EndCallButton
            buttonLabel={callState.buttonLabel}
            iconDefinition={iconDefinition}
            stopWatch={stopWatch}
            onClick={isUserAllowedToEndCallAlready
              ? endCall
              : undefined}
          />
        )}
      </div>

      <style jsx>
        {`
          div.videocall-handler-container {
            align-items: center;
            background-color: ${theme.brandColors.white};
            display: flex;
            height: 100%;
            justify-content: end;
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default VideoHandler;
