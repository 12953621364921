// Node Modules
import css from "styled-jsx/css"

// Theme
import theme from "@/theme";

export const StylesHeader = () => {
  return css.resolve`
    .header {
      height: 9.6%;
      position: relative;
    }

    .header:after {
      content: '';
      border-bottom: 1px solid ${theme.brandColors.breaklineGray};
      bottom: 0;
      left: 0%;
      position: absolute;
      width: 100%;
    }

    .logo-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
    `
}

