// Constants
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Node Modules
import {
  Dispatch,
  SetStateAction
} from "react";

// Scripts
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Services
import dataLayerService from "@/components/services/dataLayerService";

// Theme
import theme from "@/data/theme";

// Types
import ICallState from "@/interfaces/twilio/video/ICallState";
import ICustomIconDefinitionProps from "@/interfaces/global-components/icons/ICustomIconDefinitionProps";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import { IGlobalLayoutFieldsModified } from "@/interfaces/layouts/IGlobalLayoutFieldsModified";
import { IVideoProviderProps } from "@/classes/video-call/IVideoProviderProps";

export abstract class VideoProvider {
  protected deviceInformation: IDeviceInformation;
  protected disableTimer: () => void;
  protected enableTimer: () => void;
  protected globalLayoutFields: IGlobalLayoutFieldsModified;
  protected setCallState: Dispatch<SetStateAction<ICallState>>;
  protected setIconDefinition: Dispatch<SetStateAction<ICustomIconDefinitionProps>>;
  protected setIsInboundCall: Dispatch<SetStateAction<boolean>>;
  protected setIsUserAllowedToEndCallAlready: React.Dispatch<React.SetStateAction<boolean>>;
  protected setPlayCallChime: Dispatch<SetStateAction<boolean>>;
  public isCallActive: boolean = false;
  
  constructor(props: IVideoProviderProps) {
    this.deviceInformation = props.deviceInformation;
    this.globalLayoutFields = props.globalLayoutFields;
    this.disableTimer = props.disableTimer;
    this.enableTimer = props.enableTimer;
    this.setCallState = props.setCallState;
    this.setIconDefinition = props.setIconDefinition;
    this.setIsInboundCall = props.setIsInboundCall;
    this.setIsUserAllowedToEndCallAlready = props.setIsUserAllowedToEndCallAlready;
    this.setPlayCallChime = props.setPlayCallChime;
  }

  abstract startOutboundCall(): Promise<void>;
  abstract answerInboundCall(): Promise<void>;
  abstract endCall(): Promise<void>;
  abstract reconnectToRoomAudio: () => void;
  
  public getRemoteMediaDiv(): HTMLDivElement {
    return document.getElementById("remote-media") as HTMLDivElement;
  };

  protected resetCallState = (): void => {
    this.setCallState(callState => ({
      ...callState,
      buttonLabel: this.globalLayoutFields?.footerCallButtonText!,
      callStatus: callStatuses.inactive,
    }));

    this.setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.brandColors.white,
    }));
    
    this.setPlayCallChime(false);
    this.setIsInboundCall(false);
    this.setIsUserAllowedToEndCallAlready(false);
    this.enableTimer();
  };

  protected setAnsweredCallState = (): void => {
    this.setCallState(callState => ({
      ...callState,
      buttonLabel: this.globalLayoutFields?.footerEndCallButtonText!,
      callStatus: callStatuses.active,
    }));

    dataLayerService.pushEvent({
      event: dataLayerTypes.events.kioskVideoCallAnswered,
    });

    this.setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.brandColors.white,
    }));
  };

  protected setDialingCallState = (isInboundCall: boolean): void => {
    const dataLayerEventName: string = isInboundCall
      ? dataLayerTypes.events.kioskInboundVideoCall
      : dataLayerTypes.events.kioskOutboundVideoCall;

    dataLayerService.pushEvent({
      event: dataLayerEventName,
    });

    this.setCallState(callState => ({
      ...callState,
      buttonLabel: this.globalLayoutFields?.footerDialingButtonText!,
      callStatus: callStatuses.connecting,
    }));

    this.setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.brandColors.black,
    }));

    this.disableTimer();
  };
}
