// Types
import IHoursOfOperationDetails from "@/interfaces/splash-screen/IHoursOfOperationDetails";

// Utils
import { hoursOfOperationParsingUtils } from "@/scripts/hourOfOperationParsingUtils";

export const buildNSCHoursTooltipText = (hoursOfOperation: IHoursOfOperationDetails): string => {
  const openingHours = hoursOfOperationParsingUtils.getOpeningHours(hoursOfOperation);
  const closingHours = hoursOfOperationParsingUtils.getClosingHours(hoursOfOperation);
  return `Agents are available from ${openingHours} ${closingHours}`;
}

export const buildOpenSchedule = ():IHoursOfOperationDetails => {
  const currentHour = new Date().getHours();

  let schedule = "";

  if (currentHour >= 0 && currentHour < 12) {
    schedule = "12:00 AM - 12:00 PM";
  } else {
    schedule = "12:00 PM - 12:00 AM";
  }
  return {
    friday: schedule,
    monday: schedule,
    saturday: schedule,
    sunday: schedule,
    thursday: schedule,
    tuesday: schedule,
    wednesday: schedule,
    type: "",
  };
}

export const buildCloseSchedule = (): IHoursOfOperationDetails => {
  const currentHour = new Date().getHours();
  let schedule = "";

  if (currentHour >= 0 && currentHour < 12) {
    schedule = "12:00 PM - 12:00 AM";
  } else {
    schedule = "12:00 AM - 12:00 PM";
  }
  return {
    friday: schedule,
    monday: schedule,
    saturday: schedule,
    sunday: schedule,
    thursday: schedule,
    tuesday: schedule,
    wednesday: schedule,
    type: "",
  };
}

export const buildSchedule = ():IHoursOfOperationDetails => {
  return buildOpenSchedule();
}
