// Components
import ErrorBoundary from "@/components/404/ErrorBoundary";
import Footer from "@/gc/footer/Footer";
import Header from "@/gc/header/Header";
import KeyboardWrapper from "@/components/global-components/keyboard/KeyboardWrapper";
import Loader from "@/gc/animations/loader/Loader";
import OptimizelyScriptComponent from "@/components/script-tags/OptimizelyScriptComponent";
import SystemOutageTopBanner from "@/gc/banner/SystemOutageBanner";
import TimerModal from "@/gc/modals/TimerModal";

// Context
import { useDataDogContext } from "@/context/DataDogContext";
import { useErrorBoundaryContext } from "@/context/ErrorBoundaryContext";
import { useKeyboardContext } from "@/context/KeyboardContext";
import { useLoaderContext } from "@/context/LoaderContext";
import { useSystemOutageContext } from "@/context/SystemOutageContext";
import { useTimerContext } from "@/context/TimerContext";

// Enums
import { KeyboardType } from "@/enums/KeyboardType";

// Node Modules
import Head from "next/head";
import _JSXStyle from "styled-jsx/style";
import { useEffect } from "react";
import { useRouter } from "next/router";

// Scripts
import { accessibilitySizeOptions } from "@/scripts/accessibilityHelper";

// Types
import IKioskAppProps from "@/interfaces/IKioskAppProps";

const KioskApp = ({
  children,
  content,
  disableBackButton = false,
  disableLayout,
  handleBack,
  headTitle,
  homeButtonOnClick,
  isHomeButtonEnabled = true,
  passiveTimerFunctionality,
  shouldShowDiagnosticsModal = false,
}: IKioskAppProps) => {
  const router = useRouter();

  const {
    initializeDataDogRealUserMonitoring,
  } = useDataDogContext();

  const {
    isLoading,
  } = useLoaderContext();

  const {
    isTimerEnabled,
  } = useTimerContext();

  const {
    Keyboard,
    setKeyboard,
  } = useKeyboardContext();

  const {
    hasError,
    setHasError,
  } = useErrorBoundaryContext();

  const {
    showSystemOutageBanner,
  } = useSystemOutageContext()

  useEffect(() => {
    if (isTimerEnabled) {
      setKeyboard({
        ...Keyboard,
        showKeyboard: false,
      });
    }
  }, [isTimerEnabled]);

  useEffect(() => {
    const attractionLoopQueryParamExists: boolean = router?.query.displayAttractionLoop == "true";
    if (!attractionLoopQueryParamExists) {
      initializeDataDogRealUserMonitoring();
    }
  }, [router.query]);

  let currentZoomValue: number;
  let isFontSizeSmall: boolean;
  let showScrollBar: boolean;
  if (typeof window !== "undefined") {
    currentZoomValue = Number(sessionStorage.getItem("fontSizeZoomValue"));

    isFontSizeSmall = currentZoomValue == accessibilitySizeOptions.small.zoom;
    showScrollBar = !isFontSizeSmall || Keyboard.showKeyboard;
  }

  const getChildrenContainerBodyHeight = () => {
    switch (Keyboard.layoutName) {
      case KeyboardType.default:
        return "66.3%";
      case KeyboardType.email:
        return "61.5%";
      case KeyboardType.numeric:
        return "69.5%";
    }
  };

  const keyboardIsEnabled = process.env.KIOSK_KEYBOARD_ENABLED == "true";
  const childrenBodyHeight = Keyboard.showKeyboard && keyboardIsEnabled
    ? getChildrenContainerBodyHeight()
    : "83.3%";

  const resetHomePage = async () => {
    await router.push("/");
    setHasError(false);
  };

  return (
    <>
      <Head>
        <title>{headTitle ?? "Extra Space"}</title>
      </Head>
      {!disableLayout && (
        <Header
          content={content}
          shouldShowDiagnosticsModal={shouldShowDiagnosticsModal}
        />
      )}

      <ErrorBoundary
        content={content}
        hasError={hasError}
        setHasError={setHasError}
      >
        <div id="children-body">
          {showSystemOutageBanner && (
            <SystemOutageTopBanner
              testId="system-outage-banner"
            />
          )}
          {children}
        </div>
        {isLoading && <Loader />}
      </ErrorBoundary>
      {isTimerEnabled && (
        <TimerModal
          content={content}
          passiveTimerFunctionality={passiveTimerFunctionality}
        />
      )}
      {!disableLayout && (
        <Footer
          content={content}
          disableBackButton={hasError || disableBackButton}
          handleBack={handleBack}
          homeButtonOnClick={hasError
            ? () => resetHomePage()
            : homeButtonOnClick}
          isHomeButtonEnabled={isHomeButtonEnabled}
        />
      )}
      {keyboardIsEnabled && (
        <KeyboardWrapper />
      )}
      <OptimizelyScriptComponent />
      <_JSXStyle id="kioskApp">
        {`
          #children-body {
            flex: 1 0 auto;
            height: ${childrenBodyHeight};
            overflow: ${showScrollBar
            ? "auto"
            : "hidden"};
            position: relative;
            width: 100%;
            zoom: ${currentZoomValue};
          }
        `}
      </_JSXStyle>
    </>
  );
};

export default KioskApp;
