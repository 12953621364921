// Components
import { BLOCKS } from "@contentful/rich-text-types";
import RichText from "@/gc/rich-text/RichText";
import { useEffect } from "react";

// Context
import { useLoaderContext } from "@/context/LoaderContext";

// Node Modules
import PropTypes from "prop-types";

const propTypes = {
  errorBodyText: PropTypes.any,
  errorImage: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
  }),
  errorImageHeight: PropTypes.number.isRequired,
  errorImageWidth: PropTypes.number.isRequired,
  errorTitle: PropTypes.objectOf(PropTypes.any).isRequired,
};

const ErrorBody = ({
  errorBodyText,
  errorImage,
  errorImageHeight,
  errorImageWidth,
  errorTitle,
}) => {
  const {
    hideLoader,
  } = useLoaderContext();

  useEffect(() => {
    hideLoader();
  }, [])

  const modalTitle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <>
            <h1 data-testid="errorTitle">{children}</h1>
            <style jsx>
              {`
              h1{
              text-align:center;
              }
              `}
            </style>
          </>
        );
      },
    },
  };

  const modalParagraphs = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <>
            <p>{children}</p>
            <style jsx>
              {`
                p {
                  padding-bottom: 3rem;
                }
              `}
            </style>
          </>
        );
      },
    },
  };

  return (
    <div className="error-body">
      <main>
        <div className="error-content">
          <RichText
            document={errorTitle}
            options={modalTitle}
          />
          <img
            data-testid="errorImage"
            height={errorImageHeight || "auto"}
            src={errorImage.fields.file.url}
            width={errorImageWidth || "auto"}
          />
          <div
            className="error-body-text"
            data-testid="errorBody"
          >
            <RichText
              document={errorBodyText}
              options={modalParagraphs}
            />
          </div>
        </div>
      </main>
      <style>
        {`
            h1{
              text-align:center;
            }
            img{
              align-self: center;
              justify-self: center;
              margin-Top:5rem;
            }
            main {
              display:flex;
              align-items: center;
              justify-content: space-around;
              flex-direction:inherit
          }
             .error-body{
                  display:flex;
                  justify-content: center;
                  height:83%;

              }
              .error-body-text{
              width:70%;
               justify-self:center;
               text-align:center;
               padding:5% 0% 10% 0
              }
              .error-text-component{
                display:flex;
                justify-content:center;
                width:95%;
              }
              .error-content{
                display:grid;
              }
              .error-return-home-text{
                justify-self:center;
                font-size:3.8rem;
                line-height:1.4rem;
                font-family: arial;
              }
              `}
      </style>
    </div>

  );
};

ErrorBody.propTypes = propTypes;

export default ErrorBody;
